.modal {
  position: fixed; 
  z-index: 1; 
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items:center;
}


.modal-content {

}


.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}



.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

.modal-content {
  width:fit-content;
  transform: scale(0.8);
  opacity: 0;
  animation: slideIn 0.3s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  to {
    transform: scale(1);
    opacity: 1;
  }
}


.modal-overlay.modal-hide {
  animation: fadeOut 0.3s forwards;
}

.modal-content.modal-hide {
  animation: slideOut 0.3s forwards;
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

@keyframes slideOut {
  to {
    transform: scale(0.8);
    opacity: 0;
  }
}
