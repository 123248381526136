
.ql-editor {
  padding: 16px;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}


.ql-editor p {
  margin-bottom: 16px;
}


.ql-editor h1 {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 16px;
}
.ql-editor h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 16px;
}
.ql-editor h3 {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 16px;
}


.ql-editor ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 16px;
}
.ql-editor ol {
  list-style-type: decimal;
  padding-left: 20px;
  margin-bottom: 16px;
}
.ql-editor li {
  margin-bottom: 8px;
}


.ql-editor blockquote {
  border-left: 4px solid #ddd;
  padding-left: 16px;
  font-style: italic;
  color: #666;
  margin-bottom: 16px;
}


.ql-editor pre {
  background-color: #f5f5f5;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 16px;
  overflow-x: auto;
}


.ql-editor a {
  color: #007bff;
  text-decoration: underline;
}
.ql-editor a:hover {
  color: #0056b3;
}


.ql-editor b,
.ql-editor strong {
  font-weight: bold;
}
.ql-editor i,
.ql-editor em {
  font-style: italic;
}


.ql-editor img {
  max-width: 100%;
  height: auto;
  margin-bottom: 16px;
}


.ql-editor table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 16px;
}
.ql-editor th,
.ql-editor td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}


.ql-editor hr {
  border: none;
  border-top: 1px solid #ddd;
  margin: 16px 0;
}
