
.c__alert {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	align-items: center;
	justify-content: center;
	z-index: 1200;
	transition: 0.3s;
	display: none;
	overflow: hidden;

	&.toggleOpen {
		display: flex;
		opacity: 1;
		animation-name: fadeIn;
		animation-duration: 0.3s;
	}

	&.toggleClose {
		opacity: 0;
		animation-delay: 0.5s;
		animation-name: fadeOut;
		animation-duration: 0.3s;

		.modal-container {
			animation-name: zoomOut;
			animation-duration: 0.2s;
		}
	}


	

	.alert-container {
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		padding: 16px;
		position: relative;
		z-index: 1000;

		.alert-body {
			display: flex;
			justify-content:space-between;
			flex-direction:column;
			min-height:180px;
		
			.alert-content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				white-space: pre;
			}
		}
	}
}

@-webkit-keyframes zoom {
	from {
		-webkit-transform: scale(0.8);
	}
	to {
		-webkit-transform: scale(1);
	}
}

@keyframes zoomIn {
	from {
		transform: scale(0.8);
	}
	to {
		transform: scale(1);
	}
}

@keyframes zoomOut {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(0.8);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fadeOut {
	from {
		display: flex;
	}
	to {
		display: none;
	}
}
