@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&family=Noto+Sans+KR:wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



.jp-font {
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}