.notice_item {
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
}

.question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}

.question_title {
    font-size: 24px;
}

.answer {
    font-weight: bold;
    color: orange;
    font-size: 18px;
    height: 0;
    overflow: hidden;
    transition: all 0.5s;
}

.notice_item.active .answer {
    height: 30px;
}



.delete {
    position: absolute;
    border: 1px solid blue;
    right: 0;
    top: 0;
}
.edit {
    border: 1px solid blue;
    position: absolute;
    top: 0;
    right: 50px;
}