@tailwind base;
@tailwind components;
@tailwind utilities;

@import './style/font.css';
@import './style/animation.css';
@import './style/sub.css';
@import './style/notice.css';
@import './style/event.css';
@import './style/modal.css';
@import './style/alert.css';
@import './style/list.css';
@import './style/ql.css';

@font-face {
  font-family: 'MinSans';
  src: url('./assets/fonts/MinSans-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'MinSans';
  src: url('./assets/fonts/MinSans-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'MinSans';
  src: url('./assets/fonts/MinSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'MinSans';
  src: url('./assets/fonts/MinSans-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'MinSans';
  src: url('./assets/fonts/MinSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}



body {
  margin: 0;
  font-family:'MinSans','Noto Sans KR';
}

button {
  line-height:normal;
}

li {
  line-height:normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-image {
  background-size: contain; /* 배경 이미지를 전체 영역에 맞춤 */
  background-position: center; /* 배경 이미지를 중앙에 위치시킴 */
  background-repeat: no-repeat; /* 배경 이미지 반복하지 않음 */
  width: 100%; /* 배경이 적용될 전체 영역 */
  height:100%;
  min-height:100vh;
}

.bg-illust {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height:100%;
}

.bg-illust-contain {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height:100%;
}

.bg-illust-contain-repeat {
   
  background-position: center; 
  background-repeat: repeat;
  width: 100%; 
  height:100%;
}


.bg-image-repeat {
  background-size: contain;
  background-position: center;
  background-repeat: repeat;
  width: 100%;
  height:100%;
  min-height:100vh;
}

.flex-center {
  display:flex;
  justify-content:center;
  align-items:center;
}

.slick-dots {
  bottom: -50px !important;
}

.double-text {
  text-shadow: -2px 0px black, 0px 2px black, 2px 0px black, 0px -2px black;
}

.line-normal {
  line-height: normal;
}

.embla {
  overflow: hidden;
}
.embla__container {

  display: flex;

}


.embla__slide {
  margin: 20px;
  flex: 0 0 100%;
  min-width: 0;
}

.absolute-center{
  position:absolute;
  left:50%;
  transform:translateX(-50%);
} 