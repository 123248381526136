
@keyframes bounce {
    0%, 100% {
        transform: translateY(0); 
    }
    50% {
        transform: translateY(-10px); 
    }
}


.link-icon {
    display: inline-block;
    
}

.link:hover .link-icon {
    animation: bounce 0.6s infinite; 
}


@keyframes slowbounce {
    0%, 100% {
        transform: translateY(0); 
    }
    50% {
        transform: translateY(-10px); 
    }
}

.slow-bounce {
    animation: slowbounce 1.4s infinite ease-out; 
}