.hide {
    font-size: 0;
    height: 0;
    left: -9999px;
    overflow: hidden;
    position: absolute;
    text-indent: -9999px;
    width: 0;
}


.event table {
    border-top: 2px solid black;
}

.event table th {
    height: 62px;
    vertical-align: middle;
    font-size: inherit;
    font-weight: 500;
    letter-spacing: -0.06em;
}

.event table tr:last-child td {
    border-bottom: 1px solid grey;
}

.event table td {
    border-top: 1px solid grey;
    height: 60px;
    vertical-align: middle;
    text-align: center;
    font-weight: 300;
}
